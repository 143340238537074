import React from 'react'
import './Timeline.css'
import img1 from '../../../Assets/story1.webp'
import img2 from '../../../Assets/story2.webp'
import img3 from '../../../Assets/story3.webp'
import img4 from '../../../Assets/story4.webp'
import img5 from '../../../Assets/story5.webp'
import img6 from '../../../Assets/story6.webp'
import signature from '../../../Assets/signature.webp'
import boys from '../../../Assets/boys.jpg'

export default function TimelineMobile() {
  return (
    <div id='timeline-mobile'>
            <p>Our journey began in the sunny city of San Diego, California, where our family immersed ourselves in the vibrant local culture. It was here that we first discovered the true art of 100% blue agave reposado tequila, thanks to a dear friend from Jalisco, the heart of tequila country. This sparked a passion for creating something extraordinary—a fusion of tradition and bold innovation. </p>
            <img className='timeline-img-mobile' src={img1} />

            <p>After returning to the UK, we stayed connected to our roots in Jalisco and embarked on a mission to bring the authentic essence of reposado tequila to Europe.</p>
            <img className='timeline-img-mobile' src={img2} />

            <p>The last two years have been an adventure like no other. We took a deep dive into the heart of Mexico,
                traveling to Jalisco where all of this made possible. It wasn’t just about finding the best suppliers; we
                spent countless hours testing local flavours until we discovered the perfect blends that gives our
                tequila its unique twist.
            </p>
            <img className='timeline-img-mobile' src={img3} />


            <p>But it didn’t stop there. I immersed myself in the region’s history, learning the meticulous process of crafting 100% blue agave reposado tequila. Whether it be harvesting the agave or the aging process, which was a true education in tradition and craftsmanship. Along the way, I made lifelong friends who have been instrumental in making this dream a reality. After countless tasting sessions and creative brainstorming, we finally landed on a bold bottle design that reflects our intentions to change the Tequila market.</p>
            <img className='timeline-img-mobile' src={img4} />

                    

            <p>One of the many great assets of our flavoured reposado tequila is that it’s full of rich, natural flavour.
                Meaning you can skip the sugary mixers resulting in fewer calories in your glass. For the health-
                conscious individual, this is a big win. More sugar in your drinks usually leads to worse hangovers, but our reposado, infused with flavours like black cherry, vanilla bean, tamarind,
                or coffee, lets you enjoy a deliciously complex drink without as much next-day regret.
            </p>          

            <img className='timeline-img-mobile' src={img5} />

            <p>Our flavoured reposado tequila is aged to perfection and offers a smooth, satisfying taste that
                stands on its own or with just a splash of something simple. It’s the perfect choice for those who
                want to indulge without derailing their health goals, making every sip a celebration you can feel
                good about.
            </p>
            <img className='timeline-img-mobile' src={boys} />

            {/*<img src={signature} id='signature-mobile'/>*/}
            <p id='signature-mobile' style={{fontFamily: 'Signature', fontSize: '12vw'}}>MJ & Sons</p>

    </div>
  )
}
