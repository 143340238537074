import React from 'react'
import './Modal.css'
import logo from '../../../Assets/logo-new.webp'
import { toast } from 'react-toastify';

export default function AgeVerify({ onVerify }) {

    const handleVerify = (answer) => {
        localStorage.setItem('isVerified', answer ? 'true' : 'false');

        onVerify(answer);

        if(!answer){
            toast.error("Sorry, please come back when you're older")
        }
    }

  return (
    <div className='modal'>
        <div className='age-modal-container'>

            <img src={logo} alt='Thiago Tequila logo' style={{width: '100%', margin: '2rem auto'}}/>

            <p id='are-you'>Are you of legal drinking age?</p>

            <p id='you-must' style={{fontFamily: 'GreenGroveBold, sans-serif', color: 'white', textTransform: 'uppercase', fontWeight: 900, textAlign:'center'}}>You must be of legal drinking age in your respective country to enter. By entering you accept our terms of services in addition to our privacy and cookie policies. At thiago, we are committed to stop underage drinking and encourage responsible enjoyment of our products.</p>

            <div style={{display: 'flex', flexWrap:'wrap', justifyContent: 'center', alignItems:'center'}}>
                <button className='age-btn' onClick={() => handleVerify(true)}>
                    Yes
                </button>
                <button className='age-btn' onClick={() => handleVerify(false)}>
                    No
                </button>
            </div>

            <p style={{fontFamily: 'BigSans, sans-serif', textAlign:'center', marginTop:30}}>Copyright © {new Date().getFullYear()} Thiago Tequila Co. You must be 18 or over to consume alcohol. Please drink responsibly.</p>

        </div>
    </div>
  )
}
