import React from 'react'
import './OurStoryNew.css'
import { Link } from 'react-router-dom'
import flavouring from '../../../Assets/icons/flavouring.png'
import percent from '../../../Assets/icons/percent.png'
import sugar from '../../../Assets/icons/sugar.png'
import agave from '../../../Assets/icons/agave-icon.png'
import cactus from '../../../Assets/icons/cactus-icon.png'
import Allure from './Allure'
import ExploreFlavours from './ExploreFlavours'
import Timeline from './Timeline'

export default function OurStory() {
  return (
    <div>
      <div id='our-story-hero'>
        <p id='story-hero-header'>Made In</p>
        <p id='our-story-mexico'>mexico</p>
        <p id='our-story-desc'>
          We're shaking up the tequila game. Forget everything you know about shots and salt. Our flavoured reposado is crafted from the finest blue agave, grown in the rich red soil of the Jalisco mountains.
        </p>
     
        <Link id='our-story-btn' className='thiago-btn' to={'/products'}>
        Join the Party
        </Link>
      </div>

      <div id='our-story-highlights'>
        <p id='story-highlight-txt'>It's a true taste of Mexico, with a seriously modern twist. We've taken a classic and kicked it up a notch, creating a bold 40% ABV tequila that's packed with flavour, and infused with naturally sourced ingredients.</p>

        <div id='highlights'>
          <div className='story-highlight-container'>
            <img src={flavouring} className='story-highlight-icon'  alt='Thiago Flavoured Tequila'/>
            <p>Natural Flavouring</p>
          </div>
          <div className='story-highlight-container'>
            <img src={percent} className='story-highlight-icon'  alt='Thiago Flavoured Tequila'/>
            <p>40% ABV</p>
          </div>
          <div className='story-highlight-container'>
            <img src={sugar} className='story-highlight-icon'  alt='Thiago Flavoured Tequila'/>
            <p>Natuarlly Low In Sugar</p>
          </div>
          <div className='story-highlight-container'>
            <img src={agave} className='story-highlight-icon'  alt='Thiago Flavoured Tequila'/>
            <p>Made From 100% Blue Agave</p>
          </div>
          <div className='story-highlight-container'>
            <img src={cactus} className='story-highlight-icon'  alt='Thiago Flavoured Tequila'/>
            <p>Made In Mexico</p>
          </div>
        </div>
  
      </div>

      <ExploreFlavours />

      <Allure />

      <Timeline />
      
    </div>
  )
}
