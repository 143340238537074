import React, { useState } from 'react'
import './ExploreFlavours.css'
import coffee from '../../../Assets/coffee.webp'
import cherries from '../../../Assets/cherries.webp'
import vanilla from '../../../Assets/vanilla-pod.webp'
import tamarind from '../../../Assets/tamarind.webp'
import { Link } from 'react-router-dom'

export default function ExploreFlavours() {

  const [flavour, setFlavour ] = useState("")

  const handleClick = (choice) => {
    if(flavour === choice){
      setFlavour("")
    } else {
      setFlavour(choice)
    }
  }

  return (
    <div id='explore-flavours'>

      <div id='explore-header'>
        <p id='explore-txt'>Explore</p>
        <p id='our-flavours'>our flavours</p>
      </div>
    

      <div 
        className={flavour === "" ?  'explore-flavour-container hover' : flavour === 'Coffee' ? 'explore-flavour-container-expanded hover' : 'explore-flavour-container-shrunk' } 
        style={{backgroundImage: `url(${coffee})`}}
        onClick={() => handleClick("Coffee")}
      >
        <p className='explore-flavour-title'>Coffee</p>
        <div className={flavour === 'Coffee' ? 'flavour-overview' : 'flavour-overview-hidden'}>
          <img className='flavour-overview-img' src={coffee}/>
          <div className='flavour-overview-info' style={flavour === 'Coffee' ? {backgroundColor: '#784E21'} : {}}>
              <p className='flavour-overview-header' style={{color: '#F4EEE0'}}>Coffee</p>
              <p className='flavour-overview-txt'>Offering a smooth blend of bold roasted coffee notes with the subtle oak-aged essence of Reposado. We use no artificial dyes or colours, allowing authentic Reposado to shine through. Ideal for those who enjoy a refined drink with an adventurous twist, it elevates your experience without overwhelming the palate. Perfect for lively gatherings or cosy nights in, try it in cocktails like a Mexican Alexander or Thiago Tequila Espresso Martini.</p>
              <Link to={'/products'}>
              <button className='thiago-btn' style={{backgroundColor: '#F4EEE0', color: '#784E21'}}>
              Join the Party
              </button>
              </Link>
          </div>
        </div>
      </div>

      <div 
        className={flavour === "" ?  'explore-flavour-container hover' : flavour === 'Cherry' ? 'explore-flavour-container-expanded hover' : 'explore-flavour-container-shrunk' } 
        style={{backgroundImage: `url(${cherries})`}}
        onClick={() => handleClick("Cherry")}
      >
        <p className='explore-flavour-title'>Black Cherry</p>
        <div className={flavour === 'Cherry' ? 'flavour-overview' : 'flavour-overview-hidden'}>
          <img className='flavour-overview-img' src={cherries}/>
          <div className='flavour-overview-info' style={flavour === 'Cherry' ? {backgroundColor: '#6E2070'} : {}}>
          <p className='flavour-overview-header' style={{color: '#B0F6FA'}}>Black Cherry</p>
              <p className='flavour-overview-txt'>Made from 100% blue agave and infused with rich black cherry, this Reposado offers a smooth texture and balanced sweetness. The global appeal of black cherry inspired us to create a unique blend that tequila enthusiasts and newcomers alike will enjoy. Ideal for sipping straight or enhancing cocktails like a Black Cherry Margarita or Paloma, it’s perfect on ice for a refreshing twist.</p>
              <Link to={'/products'}>
              <button className='thiago-btn' style={{backgroundColor: '#fff', color: '#6E2070'}}>
              Join the Party
              </button>
              </Link>
          </div>
        </div>
      </div>

      <div 
        className={flavour === "" ?  'explore-flavour-container hover' : flavour === 'Vanilla' ? 'explore-flavour-container-expanded hover' : 'explore-flavour-container-shrunk' } 
        style={{backgroundImage: `url(${vanilla})`}}
        onClick={() => handleClick("Vanilla")}
      >
        <p className='explore-flavour-title'>Vanilla</p>
        <div className={flavour === 'Vanilla' ? 'flavour-overview' : 'flavour-overview-hidden'}>
          <img className='flavour-overview-img' src={vanilla}/>
          <div className='flavour-overview-info' style={flavour === 'Vanilla' ? {backgroundColor: '#F4EEE0'} : {}}>
          <p className='flavour-overview-header' style={{color: '#885A29'}}>Vanilla</p>
              <p className='flavour-overview-txt' style={{color: '#313131'}}>This flavour was an obvious choice for its versatility, offering mixologists and home bartenders endless possibilities. The smooth profile and balanced sweetness make it ideal for unique, unforgettable drinks. Perfect in a Mexican Mule or Vanilla Paloma, our Vanilla Flavoured Reposado Tequila elevates any cocktail with its extraordinary flavour.</p>
              <Link to={'/products'}>
              <button className='thiago-btn' style={{backgroundColor: '#fff', color: '#885A29'}}>
              Join the Party
              </button>
              </Link>
          </div>
        </div>
      </div>

      <div 
        className={flavour === "" ?  'explore-flavour-container hover' : flavour === 'Tamarind' ? 'explore-flavour-container-expanded hover' : 'explore-flavour-container-shrunk' } 
        style={{backgroundImage: `url(${tamarind})`}}
        onClick={() => handleClick("Tamarind")}
      >
        <p className='explore-flavour-title'>Tamarindo Sour</p>
        <div className={flavour === 'Tamarind' ? 'flavour-overview' : 'flavour-overview-hidden'}>
          <img className='flavour-overview-img' src={tamarind}/>
          <div className='flavour-overview-info' style={flavour === 'Tamarind' ? {backgroundColor: '#FB4622'} : {}}>
          <p className='flavour-overview-header' style={{color: '#FFDF00'}}>Tamarindo Sour</p>
              <p className='flavour-overview-txt'>Inspired by our first trip to Mexico, where the tangy richness of tamarind captivated us, this tequila honours the region that made it possible. Smooth reposado meets sweet, tangy tamarind for a unique flavour. Enjoy it with sparkling water and lime or cocktails like a Tamarind Margarita or Tequila Sunrise. This tequila brings a taste of Jalisco to any occasion.</p>
              <Link to={'/products'}>
              <button className='thiago-btn' style={{backgroundColor: '#fff', color: '#FB4622'}}>
              Join the Party
              </button>
              </Link>
          </div>
        </div>
      </div>
      
    </div>
  )
}
